import React from "react"
import ShopifyLogo from "-!svg-react-loader?props[]=className:shopify!../images/svg/shopify.svg";
import ExternalLink from "-!svg-react-loader?props[]=className:shopify!../images/svg/external-link.svg";
import CozyblueHome from "../images/portfolio/cozyblue-home.png";

import Layout from "../components/layout"
import SEO from "../components/seo"

const Cozyblue = () => (
  <Layout>
    <SEO title="Project | Cozyblue Handmade" />
    <div className="project">
      <div className="heading">
        <div class="title">
          <h1>Cozyblue Handmade</h1>
          <div class="icons">
            <ShopifyLogo alt="Shopify" />
          </div>
        </div>
        <div class="site-link">
          <a href="https://cozybluehandmade.com/" target="_blank">Website <ExternalLink /></a>
        </div>
      </div>

      <div class="description">
        <p>Cozyblue helps you start one stitch at a time by giving you the tools, encouragement, and inspiration you need to create beautiful (and totally do-able!) hand embroidery.</p>
      </div>

      <div class="collaborations">
        <p>
          <span>In collaboration with</span>
          <div class="links">
            <a href="https://darlingstudio.com" target="_blank">Darling Studio</a>
            <a href="https://aeolidia.com/" target="_blank">Aeolidia</a>
          </div>
       </p>
      </div>

      <div class="project-body">
        <img src={CozyblueHome} />
     </div>
    </div>
  </Layout>
)

export default Cozyblue